import { AppData, Applicant } from "@/models/application";
import { Option } from "@/models/form";
import { School } from "@/models/school";
import { LOSType } from "@/models/opal";
import { createStore } from "vuex";
import { EnrollmentTerm, ApplicationRatePlan, InitialDraw, CandidateRefiLoan } from "@/models/application";
import { $api } from "@/services/api";
import { FeedbackRequestOption } from "@/models/feedback";
import loanConfigurationModule from "./loanConfigurationModule";
import candidateRefiLoansModule from "@/store/candidateRefiLoansModule";
import showMyRatesModule from "@/store/showMyRatesModule";
import refiLoanConfigurationModule from "@/store/refiLoanConfigurationModule";
import refiRatesSelfSelectModule from "@/store/refiRatesSelfSelectModule";
import applicantPersonalInformationModule from "@/store/applicantPersonalInformationModule";
import applicationDecisionModule from "@/store/applicationDecisionModule";
import documentsModule from "@/store/documentsModule";
import consentsModule from "@/store/consentsModule";
import store from "@/store";
import useApplication from "@/use/application";

const { getLenderConfigurationByAppId } = useApplication();

export default createStore({
  state: {
    configuration: {
      cdnUrl: "https://cusc-lz01-nonprod-cdn.azureedge.net",
    },
    appData: {} as AppData,
    lenderData: undefined,
    program: {
      pricingTiers: [],
      programDetails: {},
    },
    registrationLookups: {
      phoneTypes: [],
      membershipTypes: [],
    },
    initialDrawRequest: {} as InitialDraw,
    appRatePlans: [] as ApplicationRatePlan[],
    consentTypes: [] as any[],
    membershipTypeOptions: [] as any[],
    phoneTypeOptions: [] as Option[],
    programLimits: [],
    schools: [] as School[],
    leftPanelText: "",
    enrollmentTerms: [] as EnrollmentTerm[],
    enrollmentTermOptions: [] as Option[],
    school: {} as School,
    enrollmentTerm: {} as EnrollmentTerm,
    coursesOfStudy: [] as any[],
    coursesOfStudyOptions: [] as Option[],
    coursesOfStudyLOCOptions: [] as Option[],
    coursesOfStudyREFIOptions: [] as Option[],
    schoolLevels: [] as any[],
    schoolLevelOptions: [] as Option[],
    feedbackFormOptions: [] as FeedbackRequestOption[],
    addressUnitTypes: [] as any[],
    addressUnitTypeOptions: [] as Option[],
    addressUnitTypePrefixes: [] as string[],
    citizenshipTypes: [] as LOSType[],
    citizenshipTypeOptions: [] as Option[],
    states: [] as LOSType[],
    stateOptions: [] as Option[],
    nationalIdNumberTypes: [] as LOSType[],
    personalInfoFormLookups: {} as any,
    itinTooltipText:
      "If you have been issued an ITIN (Individual Taxpayer Identification Number) in lieu of a Social Security Number, enter the 9-digit ITIN into the SSN fields. To verify your identity, a representative will contact you and request a copy of your ITIN letter and/or current passport. We may also contact you for documentation of how long you have been living in the United States.",
    showItinIndicator: false,
    updateEmailModalActive: false,
    refiStyling: true,
    softPullEnabled: false,
    loanConfigurationInfo: {} as any,
    schoolInfoLookupsLoaded: false,
    surveyQuestionTypes: [] as LOSType[],
    housingTypeOptions: [] as Option[],
    degreeLevels: [] as Option[],
    refiProgramTypes: [],
    programTypesByDegreeLevel: [],
    selectedDegreeLevelTypeId: undefined as undefined | number,
    selectedProgramTypeId: undefined as undefined | number,
    selectedPricingTier: {} as any,
    programTypeIdMissing: false as boolean,
    degreeLevelOptions: [
      { label: "High School Diploma", value: 1 },
      { label: "Associate's Degree", value: 2 },
      { label: "Bachelor's Degree", value: 3 },
      { label: "Master's Degree", value: 4 },
      { label: "Doctoral Degree", value: 5 },
      { label: "Medical Degree", value: 6 },
    ] as Option[],
    refiProgramTypeOptionsMock: [
      {
        id: 3,
        name: "Standard",
        description: "Standard",
        loanProgramId: 3,
        loanProgramTitle: "",
        loanProgramLimit: 0,
        repaymentTerms: "",
      },
      {
        id: 4,
        name: "Advanced",
        description: "Advanced",
        loanProgramId: 4,
        loanProgramTitle: "",
        loanProgramLimit: 0,
        repaymentTerms: "",
      },
      {
        id: 5,
        name: "Medical",
        description: "Medical",
        loanProgramId: 5,
        loanProgramTitle: "",
        loanProgramLimit: 0,
        repaymentTerms: "",
      },
    ] as any[],
    refinanceOptionsMock: [
      {
        degreeLevelTypeName: "High School Diploma",
        degreeLevelTypeId: 1,
        options: [
          { label: "Standard", value: false },
          { label: "Advanced", value: false },
          { label: "Medical", value: false },
        ],
      },
      {
        degreeLevelTypeName: "Associate's Degree",
        degreeLevelTypeId: 2,
        options: [
          { label: "Standard", value: false },
          { label: "Advanced", value: false },
          { label: "Medical", value: false },
        ],
      },
      {
        degreeLevelTypeName: "Bachelor's Degree",
        degreeLevelTypeId: 3,
        options: [
          { label: "Standard", value: true },
          { label: "Advanced", value: false },
          { label: "Medical", value: false },
        ],
      },
      {
        degreeLevelTypeName: "Master's Degree",
        degreeLevelTypeId: 4,
        options: [
          { label: "Standard", value: true },
          { label: "Advanced", value: true },
          { label: "Medical", value: false },
        ],
      },
      {
        degreeLevelTypeName: "Doctoral Degree",
        degreeLevelTypeId: 5,
        options: [
          { label: "Standard", value: true },
          { label: "Advanced", value: true },
          { label: "Medical", value: false },
        ],
      },
      {
        degreeLevelTypeName: "Medical Degree",
        degreeLevelTypeId: 6,
        options: [
          { label: "Standard", value: true },
          { label: "Advanced", value: true },
          { label: "Medical", value: false },
        ],
      },
    ],
    mockProgramTypesByDegreeLevel: [
      {
        name: "Advanced Degree",
        loanProgramId: 132,
        loanProgramTitle: "YOUR CREDIT UNION - DGREFI",
        loanProgramLimit: "$150,000",
        selected: false,
        programTypeId: 5,
        rateTerm: {
          fixedRates: [
            {
              pricingTierId: 1854,
              month: 60,
              year: 5,
              rate: "5.750%",
              selected: false,
            },
            { pricingTierId: 10, month: 12 * 7, year: 7, rate: "6.300%", selected: false },
            { pricingTierId: 11, month: 12 * 10, year: 10, rate: "8.350%", selected: false },
            { pricingTierId: 12, month: 12 * 15, year: 15, rate: "8.350%", selected: false },
            { pricingTierId: 13, month: 12 * 20, year: 20, rate: "9.100%", selected: false },
          ],
          variableRates: [
            {
              pricingTierId: 2487,
              month: 60,
              year: 5,
              rate: "9.250%",
              selected: false,
            },
            { pricingTierId: 10, month: 12 * 7, year: 7, rate: "6.300%", selected: false },
            { pricingTierId: 11, month: 12 * 10, year: 10, rate: "8.350%", selected: false },
            { pricingTierId: 12, month: 12 * 15, year: 15, rate: "8.350%", selected: false },
            { pricingTierId: 13, month: 12 * 20, year: 20, rate: "9.100%", selected: false },
          ],
        },
      },
    ],
    mockProgramTypesByDegreeLevel02: [
      {
        name: "Standard",
        loanProgramId: 131,
        loanProgramTitle: "YOUR CREDIT UNION - DGREFI",
        loanProgramLimit: "$150,000",
        selected: true,
        programTypeId: 4,
        rateTerm: {
          fixedRates: [
            {
              pricingTierId: 1854,
              month: 60,
              year: 5,
              rate: "5.750%",
              selected: false,
            },
            { pricingTierId: 10, month: 12 * 7, year: 7, rate: "6.300%", selected: false },
            { pricingTierId: 11, month: 12 * 10, year: 10, rate: "8.350%", selected: false },
            { pricingTierId: 12, month: 12 * 15, year: 15, rate: "8.350%", selected: false },
            { pricingTierId: 13, month: 12 * 20, year: 20, rate: "9.100%", selected: false },
          ],
          variableRates: [
            {
              pricingTierId: 2487,
              month: 60,
              year: 5,
              rate: "9.250%",
              selected: false,
            },
            { pricingTierId: 10, month: 12 * 7, year: 7, rate: "6.300%", selected: false },
            { pricingTierId: 11, month: 12 * 10, year: 10, rate: "8.350%", selected: false },
            { pricingTierId: 12, month: 12 * 15, year: 15, rate: "8.350%", selected: false },
            { pricingTierId: 13, month: 12 * 20, year: 20, rate: "9.100%", selected: false },
          ],
        },
      },
      {
        name: "Advanced Degree",
        loanProgramId: 132,
        loanProgramTitle: "YOUR CREDIT UNION - DGREFI",
        loanProgramLimit: "$150,000",
        selected: false,
        programTypeId: 5,
        rateTerm: {
          fixedRates: [
            {
              pricingTierId: 1854,
              month: 60,
              year: 5,
              rate: "5.750%",
              selected: false,
            },
            { pricingTierId: 10, month: 12 * 7, year: 7, rate: "6.300%", selected: false },
            { pricingTierId: 11, month: 12 * 10, year: 10, rate: "8.350%", selected: false },
            { pricingTierId: 12, month: 12 * 15, year: 15, rate: "8.350%", selected: false },
            { pricingTierId: 13, month: 12 * 20, year: 20, rate: "9.100%", selected: false },
          ],
          variableRates: [
            {
              pricingTierId: 2487,
              month: 60,
              year: 5,
              rate: "9.250%",
              selected: false,
            },
            { pricingTierId: 10, month: 12 * 7, year: 7, rate: "6.300%", selected: false },
            { pricingTierId: 11, month: 12 * 10, year: 10, rate: "8.350%", selected: false },
            { pricingTierId: 12, month: 12 * 15, year: 15, rate: "8.350%", selected: false },
            { pricingTierId: 13, month: 12 * 20, year: 20, rate: "9.100%", selected: false },
          ],
        },
      },
    ],
    mockProgramTypesByDegreeLevel03: [
      {
        name: "Standard",
        loanProgramId: 131,
        loanProgramTitle: "YOUR CREDIT UNION - DGREFI",
        loanProgramLimit: "$150,000",
        selected: true,
        programTypeId: 4,
        rateTerm: {
          fixedRates: [
            {
              pricingTierId: 1854,
              month: 60,
              year: 5,
              rate: "5.750%",
              selected: false,
            },
            { pricingTierId: 10, month: 12 * 7, year: 7, rate: "6.300%", selected: false },
            { pricingTierId: 11, month: 12 * 10, year: 10, rate: "8.350%", selected: false },
            { pricingTierId: 12, month: 12 * 15, year: 15, rate: "8.350%", selected: false },
            { pricingTierId: 13, month: 12 * 20, year: 20, rate: "9.100%", selected: false },
          ],
          variableRates: [],
        },
      },
      {
        name: "Advanced Degree",
        loanProgramId: 132,
        loanProgramTitle: "YOUR CREDIT UNION - DGREFI",
        loanProgramLimit: "$150,000",
        selected: false,
        programTypeId: 5,
        rateTerm: {
          fixedRates: [
            {
              pricingTierId: 1854,
              month: 60,
              year: 5,
              rate: "5.750%",
              selected: false,
            },
            { pricingTierId: 10, month: 12 * 7, year: 7, rate: "6.300%", selected: false },
            { pricingTierId: 11, month: 12 * 10, year: 10, rate: "8.350%", selected: false },
            { pricingTierId: 12, month: 12 * 15, year: 15, rate: "8.350%", selected: false },
            { pricingTierId: 13, month: 12 * 20, year: 20, rate: "9.100%", selected: false },
          ],
          variableRates: [],
        },
      },
    ],
    mockRatePlansResponse: [
      {
        id: 1,
        applicationId: 0,
        loanProgramId: 0,
        pricingTierId: 0,
        rateTypeId: 1,
        rateTypeName: "Fixed",
        repaymentTerm: 120,
        rate: 8.35,
        rateOverridden: null,
        RateOverridenAt: null,
        apr: null,
        repayments: [
          {
            id: 0,
            rateTypeId: 1,
            repaymentTypeId: 0,
            repaymentTypeName: "",
            repaymentTerm: 0,
            repaymentTermId: 0,
            monthlyInSchoolPayment: null,
            monthlyPostSchoolPayment: 123.0,
          },
        ],
      },
    ],
    enrollmentStatuses: [
      { label: "Full-Time", value: 1 },
      { label: "Half-Time", value: 2 },
      { label: "Less Than Half-Time", value: 3 },
    ] as Option[],
    documentTypes: [] as any[],
    retrievingSchools: false as boolean,
  },
  mutations: {
    SET_RETRIEVING_SCHOOLS(state: any, flag: boolean) {
      state.retrievingSchools = flag;
    },
    SET_SURVEY_QUESTION_TYPES(state: any, surveyQuestionTypes: any) {
      state.surveyQuestionTypes = surveyQuestionTypes;
    },
    SET_HOUSING_TYPE_OPTIONS(state: any, housingTypeOptions: any) {
      state.housingTypeOptions = housingTypeOptions;
    },
    SET_INITIAL_DRAW_REQUEST(state: any, initialDrawRequest: InitialDraw) {
      state.initialDrawRequest = initialDrawRequest;
    },
    SET_APP_RATE_PLANS(state: any, appRatePlans: any) {
      state.appRatePlans = appRatePlans;
    },
    SET_CONSENT_TYPES(state: any, consentTypes: any) {
      state.consentTypes = consentTypes;
    },
    SET_LOAN_CONFIGURATION_INFO(state: any, loanConfigInfo: any) {
      state.loanConfigurationInfo = loanConfigInfo;
    },
    SET_APPLICATION_METADATA(state: any, metadata: any) {
      state.appData.application.metadata = metadata;
    },
    ADD_APPLICANT(state: any, applicant: Applicant) {
      state.appData.applicants.push(applicant);
    },
    SET_ADDRESS_UNIT_TYPES(state: any, types: LOSType[]) {
      state.addressUnitTypes = types;
    },
    SET_ADDRESS_UNIT_TYPE_OPTIONS(state: any, options: Option[]) {
      state.addressUnitTypeOptions = options;
    },
    SET_ADDRESS_UNIT_TYPE_PREFIXES(state: any, prefixes: string[]) {
      state.addressUnitTypePrefixes = prefixes;
    },
    SET_CITIZENSHIP_TYPES(state: any, types: LOSType[]) {
      state.citizenshipTypes = types;
    },
    SET_CITIZENSHIP_TYPE_OPTIONS(state: any, options: Option[]) {
      state.citizenshipTypeOptions = options;
    },
    SET_SHOW_ITIN_INDICATOR(state: any, flag: boolean) {
      state.showItinIndicator = flag;
    },
    SET_NATIONAL_ID_NUMBER_TYPES(state: any, types: LOSType[]) {
      state.nationalIdNumberTypes = types;
    },
    SET_STATES(state: any, states: LOSType[]) {
      state.states = states;
    },
    SET_STATE_OPTIONS(state: any, options: Option[]) {
      state.stateOptions = options;
    },
    SET_PERSONAL_INFORMATION_FORM_LOOKUPS(state: any, lookups: any) {
      state.personalInfoFormLookups = lookups;
    },
    SET_APP_DATA(state: any, appData: AppData) {
      window.localStorage.setItem("appInProgress", JSON.stringify(appData));
      window.localStorage.setItem("product", appData?.application?.productTypeId?.toString());

      state.appData = appData;
    },
    SET_APPLICANTS(state: any, applicants: Array<Applicant>) {
      state.appData.applicants = applicants;
    },
    SET_SCHOOLS(state: any, schools: School[]) {
      state.schools = schools;
    },
    SET_LENDER_DATA(state: any, lenderData: any) {
      // Determine if page should follow light or dark theme
      if (lenderData?.primaryColor) {
        const parsedHex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(lenderData.primaryColor);
        const rgb = parsedHex
          ? {
              r: parseInt(parsedHex[1], 16),
              g: parseInt(parsedHex[2], 16),
              b: parseInt(parsedHex[3], 16),
            }
          : null;

        if (rgb !== null) {
          // determine if light or dark theme is needed
          const luminance = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000);

          // Adding else statement just in case client changes. Mainly for testing...
          if (luminance > 118) {
            document.body.classList.add("cuscd-dark");
          } else {
            document.body.classList.remove("cuscd-dark");
          }
        }
      }

      state.lenderData = lenderData;
    },
    SET_LEFT_PANEL_TEXT(state: any, leftPanelText: string) {
      state.leftPanelText = leftPanelText;
    },
    SET_ENROLLMENT_TERMS(state: any, enrollmentTerms: any[]) {
      state.enrollmentTerms = enrollmentTerms;
    },
    SET_ENROLLMENT_TERM_OPTIONS(state: any, options: Option[]) {
      state.enrollmentTermOptions = options;
    },
    SET_SCHOOL_CONFIG(state: any, schoolInfo: any) {
      state.school = schoolInfo.school;
      state.appData.application.schoolConfiguration.schoolUndecided = schoolInfo.schoolUndecided;
      state.appData.application.schoolConfiguration.schoolName = schoolInfo.school.schoolName;
      state.appData.application.schoolConfiguration.schoolId = schoolInfo.school.id;
      state.appData.application.schoolConfiguration.graduationDate = schoolInfo.graduationDate;
      state.appData.application.schoolConfiguration.academicTerm =
        schoolInfo.academicTerm.label || schoolInfo.selectedTerm;

      if (schoolInfo?.enrollmentStatusId) {
        const enrollmentStatusId =
          typeof schoolInfo?.enrollmentStatusId === "string"
            ? parseInt(schoolInfo?.enrollmentStatusId)
            : schoolInfo?.enrollmentStatusId;
        state.appData.application.schoolConfiguration.enrollmentStatus = enrollmentStatusId;
      }

      if (schoolInfo?.degreeProgramId || schoolInfo?.degreeProgram) {
        state.appData.application.schoolConfiguration.degreeProgram =
          schoolInfo?.degreeProgramId || schoolInfo?.degreeProgram;
      }
    },
    SET_ENROLLMENT_TERM(state: any, enrollmentTerm: EnrollmentTerm) {
      state.enrollmentTerm = enrollmentTerm;
    },
    SET_PROGRAM(state: any, program: any) {
      state.program = program;
    },
    SET_PROGRAM_LIMITS(state: any, programLimits: any) {
      state.programLimits = programLimits;
    },
    SET_ANNUAL_INCOME_LIMIT(state: any, annualIncomeLimit: any) {
      state.annualIncomeLimit = annualIncomeLimit;
    },
    SET_COURSES_OF_STUDY(state: any, coursesOfStudy: any) {
      state.coursesOfStudy = coursesOfStudy;
    },
    SET_COURSES_OF_STUDY_OPTIONS(state: any, coursesOfStudyOptions: any) {
      state.coursesOfStudyOptions = coursesOfStudyOptions;
    },
    SET_COURSES_OF_STUDY_OPTIONS_LOC(state: any, coursesOfStudyOptions: any) {
      state.coursesOfStudyLOCOptions = coursesOfStudyOptions;
    },
    SET_COURSES_OF_STUDY_OPTIONS_REFI(state: any, coursesOfStudyOptions: any) {
      state.coursesOfStudyREFIOptions = coursesOfStudyOptions;
    },
    SET_SCHOOL_LEVELS(state: any, schoolLevels: any) {
      state.schoolLevels = schoolLevels;
    },
    SET_SCHOOL_LEVEL_OPTIONS(state: any, schoolLevelOptions: any) {
      state.schoolLevelOptions = schoolLevelOptions;
    },
    SET_DEGREE_LEVELS(state: any, degreeLevels: any) {
      state.degreeLevels = degreeLevels;
    },
    SET_SELECTED_DEGREE_LEVEL_TYPE_ID(state: any, degreeLevelTypeId: number) {
      state.selectedDegreeLevelTypeId = degreeLevelTypeId;
    },
    SET_SELECTED_PROGRAM_TYPE_ID(state: any, programTypeId: number) {
      state.selectedProgramTypeId = programTypeId;
    },
    SET_PROGRAM_TYPES_BY_DEGREE_LEVEL(state: any, programs: any) {
      // reset the field
      state.programTypesByDegreeLevel = [];

      state.programTypesByDegreeLevel = programs;
    },
    SET_FEEDBACK_FORM_OPTIONS(state: any, options: any) {
      state.feedbackFormOptions = options;
    },
    OPEN_UPDATE_EMAIL_MODAL(state: any) {
      state.updateEmailModalActive = true;
    },
    CLOSE_UPDATE_EMAIL_MODAL(state: any) {
      state.updateEmailModalActive = false;
    },
    SET_REGISTRATION_LOOKUPS(state: any, lookups: any) {
      state.registrationLookups = lookups;
    },
    SET_MEMBERSHIP_TYPE_OPTIONS(state: any, options: any) {
      state.membershipTypeOptions = options;
    },
    SET_PHONE_TYPE_OPTIONS(state: any, options: any) {
      state.phoneTypeOptions = options;
    },
    SET_LOAN_CONFIGURATION(state: any, loanConfiguration: any) {
      state.appData.application.loanConfiguration = loanConfiguration;
    },
    DG_SUBMIT_SCHOOL_INFO(state: any, schoolInfo: any) {
      if (!state.appData.application.schoolConfiguration) {
        state.appData.application.schoolConfiguration = {
          fullTime: undefined,
          schoolId: undefined,
          schoolName: undefined,
          schoolLevelTypeId: undefined,
          degreeProgram: undefined,
        };
      }
      state.appData.application.schoolConfiguration.fullTime = schoolInfo?.enrollment;
      state.appData.application.schoolConfiguration.schoolId = schoolInfo?.school?.id;
      state.appData.application.schoolConfiguration.schoolName = schoolInfo?.school?.schoolName;
      state.appData.application.schoolConfiguration.schoolLevelTypeId =
        typeof schoolInfo.gradeLevel === "string" ? parseInt(schoolInfo.gradeLevel) : schoolInfo.gradeLevel;
      state.appData.application.schoolConfiguration.degreeProgram =
        schoolInfo?.degreeProgram || schoolInfo?.degreeProgramId;
    },
    SUBMIT_SCHOOL_INFO(state: any, schoolInfo: any) {
      if (!state.appData.application.schoolConfiguration) {
        state.appData.application.schoolConfiguration = {
          schoolUndecided: undefined,
          fullTime: undefined,
          schoolId: undefined,
          schoolName: undefined,
          schoolLevelTypeId: undefined,
          major: undefined,
        };
      }
      state.appData.application.schoolConfiguration.schoolUndecided = schoolInfo?.schoolUndecided;
      state.appData.application.schoolConfiguration.fullTime = schoolInfo?.enrollment;
      state.appData.application.schoolConfiguration.schoolId = schoolInfo?.school?.id;
      state.appData.application.schoolConfiguration.schoolName = schoolInfo?.school?.schoolName;
      state.appData.application.schoolConfiguration.schoolLevelTypeId =
        typeof schoolInfo.gradeLevel === "string" ? parseInt(schoolInfo.gradeLevel) : schoolInfo.gradeLevel;
      state.appData.application.schoolConfiguration.major = schoolInfo?.major;
    },
    REFI_SUBMIT_SCHOOL_INFO(state: any, schoolInfo: any) {
      if (!state.appData.application?.applicationRefiSchool) {
        state.appData.application.applicationRefiSchool = {};
      }

      state.appData.application.programTypeId = state.selectedProgramTypeId;
      state.appData.application.applicationRefiSchool.schoolId = schoolInfo.school.id;
      state.appData.application.applicationRefiSchool.schoolName = schoolInfo.school.schoolName;
      state.appData.application.applicationRefiSchool.degreeProgram = schoolInfo.degreeProgram;
      state.appData.application.applicationRefiSchool.degreeLevelTypeId = schoolInfo.degreeLevelTypeId;
    },
    SET_SELECTED_PRICING_TIER(state: any, pricingTier: any) {
      state.selectedPricingTier = pricingTier;
    },
    SET_PROGRAM_TYPE_ID_MISSING_FLAG(state: any, flag: boolean) {
      state.programTypeIdMissing = flag;
    },
    SET_DOCUMENT_TYPES(state: any, types: any) {
      state.documentTypes = types;
    },
    SET_APP_DATA_CANDIDATE_REFI_LOANS(state: any, candidateRefiLoans: CandidateRefiLoan[]) {
      state.appData.application.candidateRefiLoans = candidateRefiLoans;
    },
  },
  actions: {
    setRetrievingSchools({ commit }, payload) {
      commit("SET_RETRIEVING_SCHOOLS", payload);
    },
    setAppRatePlans({ commit }, payload) {
      commit("SET_APP_RATE_PLANS", payload);
    },
    setInitialDrawRequest({ commit }, payload) {
      commit("SET_INITIAL_DRAW_REQUEST", payload);
    },
    setSelectedDegreeLevelTypeId({ commit }, payload) {
      commit("SET_SELECTED_DEGREE_LEVEL_TYPE_ID", payload);
    },
    setSelectedProgramTypeId({ commit }, payload) {
      commit("SET_SELECTED_PROGRAM_TYPE_ID", payload);
    },
    setProgramTypesByDegreeLevel({ commit }, payload) {
      commit("SET_PROGRAM_TYPES_BY_DEGREE_LEVEL", payload);
    },
    submitSchoolInfo({ commit }, payload) {
      commit("SUBMIT_SCHOOL_INFO", payload);
    },
    dgSubmitSchoolInfo({ commit }, payload) {
      commit("DG_SUBMIT_SCHOOL_INFO", payload);
    },
    async refiSubmitSchoolInfo({ state, commit, dispatch }, payload) {
      commit("REFI_SUBMIT_SCHOOL_INFO", payload);

      const lenderId = state?.appData?.application?.lenderId;
      const productTypeId = state?.appData?.application?.productTypeId;
      const programTypeId = state?.appData?.application?.programTypeId;

      const lenderConfig = await getLenderConfigurationByAppId(
        state?.appData?.application?.id,
        lenderId,
        productTypeId,
        programTypeId,
      );

      await dispatch("updateLenderData", lenderConfig);
    },
    setLoanConfigurationInfo({ commit }, payload) {
      commit("SET_LOAN_CONFIGURATION_INFO", payload);
    },
    addApplicant({ commit }, paylod) {
      commit("ADD_APPLICANT", paylod);
    },
    openUpdateEmailModal({ commit }) {
      commit("OPEN_UPDATE_EMAIL_MODAL");
    },
    closeUpdateEmailModal({ commit }) {
      commit("CLOSE_UPDATE_EMAIL_MODAL");
    },
    setApplicants({ commit }, payload) {
      commit("SET_APPLICANTS", payload);
    },
    setAppData({ commit }, payload) {
      commit("SET_APP_DATA", payload);
    },
    setLenderData({ commit }, payload) {
      commit("SET_LENDER_DATA", payload);
    },
    setDefaultLender({ commit }) {
      commit("SET_LENDER_DATA", {
        primaryColor: "#f7941e",
        secondaryColor: "#096fb1",
        program: {
          annualIncomeLimit: 36000,
        },
      });
    },
    setLeftPanelText({ commit }, payload) {
      commit("SET_LEFT_PANEL_TEXT", payload);
    },
    setAddressUnitTypes({ commit }, payload) {
      commit("SET_ADDRESS_UNIT_TYPES", payload);
    },
    setAddressUnitTypeOptions({ commit }, payload) {
      commit("SET_ADDRESS_UNIT_TYPE_OPTIONS", payload);
    },
    setAddressUnitTypePrefixes({ commit }, payload) {
      commit("SET_ADDRESS_UNIT_TYPE_PREFIXES", payload);
    },
    setCitizenshipTypes({ commit }, payload) {
      commit("SET_CITIZENSHIP_TYPES", payload);
    },
    setCitizenshipTypeOptions({ commit }, payload) {
      commit("SET_CITIZENSHIP_TYPE_OPTIONS", payload);
    },
    setNationalIdNumberTypes({ commit }, payload) {
      commit("SET_NATIONAL_ID_NUMBER_TYPES", payload);
    },
    setPersonalInfoFormLookups({ commit }, payload) {
      commit("SET_PERSONAL_INFORMATION_FORM_LOOKUPS", payload);
    },
    setStates({ commit }, payload) {
      commit("SET_STATES", payload);
    },
    setStateOptions({ commit }, payload) {
      commit("SET_STATE_OPTIONS", payload);
    },
    setSchools({ commit }, payload) {
      commit("SET_SCHOOLS", payload);
    },
    setEnrollmentTerms({ commit }, payload) {
      commit("SET_ENROLLMENT_TERMS", payload);
    },
    setEnrollmentTermOptions({ commit }, payload) {
      commit("SET_ENROLLMENT_TERM_OPTIONS", payload);
    },
    setDgSchoolConfig({ commit }, payload) {
      commit("SET_DG_SCHOOL_CONFIG", payload);
    },
    setSchoolConfig({ commit }, payload) {
      commit("SET_SCHOOL_CONFIG", payload);
    },
    setEnrollmentTerm({ commit }, payload) {
      commit("SET_ENROLLMENT_TERM", payload);
    },
    setProgram({ commit }, payload) {
      commit("SET_PROGRAM", payload);
    },
    setProgramLimits({ commit }, payload) {
      commit("SET_PROGRAM_LIMITS", payload);
    },
    setCoursesOfStudy({ commit }, payload) {
      commit("SET_COURSES_OF_STUDY", payload);
    },
    setSchoolLevels({ commit }, payload) {
      commit("SET_SCHOOL_LEVELS", payload);
    },
    setShowItinIndicator({ commit }, payload) {
      commit("SET_SHOW_ITIN_INDICATOR", payload);
    },
    setApplicationMetadata({ commit }, payload) {
      commit("SET_APPLICATION_METADATA", payload);
    },
    setLoanConfiguration({ commit }, payload) {
      commit("SET_LOAN_CONFIGURATION", payload);
    },
    setHousingTypeOptions({ state, commit }, payload) {
      commit("SET_HOUSING_TYPE_OPTIONS", payload);
    },
    setCandidateRefiLoans({ state, commit }, payload) {
      commit("SET_APP_DATA_CANDIDATE_REFI_LOANS", payload);
    },
    findAndReplaceApplicant({ state, commit }, payload) {
      const applicants = store.state.appData.applicants;

      const index = applicants?.findIndex((applicant: Applicant) => applicant.id === payload.applicantId);

      if (index !== -1) {
        applicants[index] = payload.updatedApplicant;
      }

      commit("SET_APPLICANTS", applicants);
    },
    async getProgram({ state, commit, dispatch }) {
      if (!state.program || state.program?.pricingTiers?.length === 0 || state.programLimits?.length === 0) {
        if (state?.appData?.application?.programTypeId) {
          commit("SET_PROGRAM_TYPE_ID_MISSING_FLAG", false);

          const rates = await $api.configuration.getRates(
            state?.appData?.application?.lenderId,
            state?.appData?.application?.programTypeId,
            state?.appData?.application?.productTypeId,
          );
          if (rates.pricingTiers && rates.programDetails) {
            dispatch("setProgram", rates);
            dispatch("setProgramLimits", rates.programDetails?.programLimits);
          }
        } else {
          commit("SET_PROGRAM_TYPE_ID_MISSING_FLAG", true);
        }
      }
    },
    async getFeedbackFormOptions({ state, commit }) {
      try {
        if (state.feedbackFormOptions?.length <= 0) {
          const options = await $api.feedback.getFeedbackFormOptions(2);
          commit("SET_FEEDBACK_FORM_OPTIONS", options);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getEnrollmentTerms({ state, commit }) {
      try {
        if (state.enrollmentTerms?.length <= 0 || state.enrollmentTermOptions?.length <= 0) {
          const lookups = await $api.lookups.getEnrollmentTermsLookups();
          if (lookups.length > 0) {
            commit("SET_ENROLLMENT_TERMS", lookups);

            const enrollmentTermOptions = lookups.map((o: any) => {
              return {
                label: o.description,
                value: JSON.stringify({
                  label: `${o.description}`,
                  id: `${o.id}`,
                }),
              };
            });

            commit("SET_ENROLLMENT_TERM_OPTIONS", enrollmentTermOptions);
          }
        }
      } catch (error) {
        console.log("Error: getEnrollmentTerms()", error);
      }
    },
    async getRegistrationLookups({ state, commit }) {
      try {
        if (
          state.registrationLookups?.phoneTypes?.length <= 0 ||
          state.registrationLookups?.membershipTypes?.length <= 0
        ) {
          const lookups = await $api.lookups.getRegistrationLookups();
          if (lookups.phoneTypes && lookups.membershipTypes) {
            commit("SET_REGISTRATION_LOOKUPS", lookups);
          }
          if (lookups.membershipTypes.length > 0) {
            const membershipTypeOptions = lookups.membershipTypes;
            commit("SET_MEMBERSHIP_TYPE_OPTIONS", membershipTypeOptions);
          }

          if (lookups.phoneTypes.length > 0) {
            const phoneTypeOptions = lookups.phoneTypes.map((type: any) => {
              return {
                label: type.name,
                value: type.id,
              };
            });

            commit("SET_PHONE_TYPE_OPTIONS", phoneTypeOptions);
          }
        }
      } catch (error) {
        console.log("Error: getRegistrationLookups()", error);
      }
    },
    async getSchoolInfoLookupsRefi({ state, commit }, programTypeId: number) {
      commit("SET_RETRIEVING_SCHOOLS", true);
      if (state.schools?.length <= 0 || state.selectedProgramTypeId !== programTypeId) {
        const lookups = await $api.lookups.getRefiSchoolInfoLookups(programTypeId, state.selectedDegreeLevelTypeId);
        if (lookups.coursesOfStudy?.length > 0) {
          commit("SET_COURSES_OF_STUDY", lookups.coursesOfStudy);
          const coursesOfStudy = (lookups.coursesOfStudy || []).map((o: any) => {
            return {
              label: o.description,
              value: o.id,
            };
          });

          const coursesOfStudyREFI = (lookups.coursesOfStudy || []).map((o: any) => {
            return {
              label: o.description,
              value: o.id,
            };
          });

          commit("SET_COURSES_OF_STUDY_OPTIONS", coursesOfStudy);
          commit("SET_COURSES_OF_STUDY_OPTIONS_REFI", coursesOfStudyREFI);
        }

        const res = await $api.school.getSchoolsByProgram(
          programTypeId,
          state.appData?.application?.productTypeId,
          state?.appData?.application?.lenderId,
        );

        if (res?.schools?.length > 0) {
          commit("SET_SCHOOLS", res.schools);
        }
      }
      commit("SET_RETRIEVING_SCHOOLS", false);
    },
    async getSchoolInfoLookups({ state, commit }) {
      try {
        if (state.coursesOfStudy?.length <= 0 || state.schoolLevels?.length <= 0) {
          const lookups = await $api.lookups.getSchoolInfoLookups(state?.appData?.application?.programTypeId);

          if (lookups.coursesOfStudy?.length > 0) {
            commit("SET_COURSES_OF_STUDY", lookups.coursesOfStudy);
            const coursesOfStudy = (lookups.coursesOfStudy || []).map((o: any) => {
              return {
                label: o.description,
                value: o.id,
              };
            });

            console.log(lookups.coursesOfStudy);
            const coursesOfStudyLOC = (lookups.coursesOfStudy || []).map((o: any) => {
              return {
                label: o.description,
                value: o.majorName,
              };
            });

            console.log(coursesOfStudyLOC);

            commit("SET_COURSES_OF_STUDY_OPTIONS", coursesOfStudy);
            commit("SET_COURSES_OF_STUDY_OPTIONS_LOC", coursesOfStudyLOC);
          }

          if (lookups.schoolLevels?.length > 0) {
            commit("SET_SCHOOL_LEVELS", lookups.schoolLevels);

            const schoolLevels = (lookups.schoolLevels || []).map((o: any) => {
              return {
                label: o.name,
                value: o.id || "",
              };
            });

            commit("SET_SCHOOL_LEVEL_OPTIONS", schoolLevels);
          }
        }

        if (state.schools?.length <= 0) {
          const res = await $api.school.getSchoolsByProgram(
            state.appData?.application?.programTypeId,
            state.appData?.application?.productTypeId,
            state?.appData?.application?.lenderId,
          );
          if (res?.schools?.length > 0) {
            commit("SET_SCHOOLS", res.schools);
          }
        }
      } catch (error) {
        console.log("Error: getSchoolInfoLookups()", error);
      }
    },
    async getAdditionalInfoLookups({ commit, state }, applicantType: string) {
      try {
        const lookups = await $api.lookups.getAdditionalInfoLookups(
          applicantType,
          state?.appData?.application?.productTypeId,
        );
        if (lookups.error) {
          return { error: true };
        } else {
          if (lookups.housingTypes && lookups?.housingTypes?.length > 0) {
            const options = lookups.housingTypes.map((o: any) => {
              return {
                label: o.description || o.label,
                value: o.id || o.value,
              };
            });

            commit("SET_HOUSING_TYPE_OPTIONS", options);
          }
        }
      } catch (error) {
        console.log("Error: getAdditionalInfoLookups()", error);
      }
    },
    async getConsentTypes({ state, commit }) {
      try {
        if (state.consentTypes?.length <= 0) {
          const lookups = await $api.lookups.getConsentTypes();
          if (lookups?.length > 0) {
            commit("SET_CONSENT_TYPES", lookups);
          }
        }
      } catch (error) {
        console.log("Error: getConsentTypes()", error);
      }
    },
    async getSurveyQuestionTypes({ state, commit }) {
      try {
        if (state.surveyQuestionTypes?.length <= 0) {
          const lookups = await $api.lookups.getSurveyQuestionTypes();
          if (lookups.success) {
            commit("SET_SURVEY_QUESTION_TYPES", lookups.results);
          }
        }
      } catch (error) {
        console.log("Error: getSurveyQuestionTypes()", error);
      }
    },
    async getAppRatePlans({ state, commit }) {
      const appRatePlans = await $api.applications.getApplicationRatePlansByApplicationId(
        state?.appData?.application?.id,
        state?.appData?.application?.lenderId,
      );
      if (appRatePlans && appRatePlans?.length > 0) {
        const sortedRatePlans = appRatePlans.sort((a: any, b: any) => a.rateTypeId - b.rateTypeId);
        commit("SET_APP_RATE_PLANS", sortedRatePlans);
      }
    },
    async getDegreeLevels({ state, commit }) {
      try {
        if (state.degreeLevels?.length <= 0) {
          const response = await $api.refi.getDegreeLevels();

          if (response?.length > 0) {
            commit("SET_DEGREE_LEVELS", response);
          }
        }
      } catch (error) {
        console.log("Error: getDegreeLevels()", error);
      }
    },
    async getProgramsByDegreeLevelTypeId({ state, commit }, degreeLevelTypeId: number) {
      try {
        const response = await $api.refi.getProgramsByDegreeLevelTypeId(
          state.appData?.application?.lenderId,
          state.appData?.application?.productTypeId,
          degreeLevelTypeId,
        );

        if (response?.data && response?.data?.length > 0) {
          commit("SET_PROGRAM_TYPES_BY_DEGREE_LEVEL", response.data);
        } else {
          commit("SET_PROGRAM_TYPES_BY_DEGREE_LEVEL", []);
        }
      } catch (error) {
        console.log("Error: getProgramsByDegreeLevelTypeId()", error);
      }
    },
    async updateLenderData({ dispatch }, lenderConfig: any) {
      if (lenderConfig?.lid || !lenderConfig?.error) {
        window.localStorage.setItem("lenderData", JSON.stringify(lenderConfig));
        await dispatch("setLenderData", lenderConfig);
      } else {
        if (lenderConfig?.redirectUrl) {
          window.location.href = `${lenderConfig?.redirectUrl}`;
        }
      }
    },
    async saveSelectedCreditScoreRange({ dispatch, state, commit }, payload) {
      await dispatch("getSurveyQuestionTypes");

      const questionId = store.state.surveyQuestionTypes?.find(
        (s: any) => s.name?.trim() === "SelectedCreditRange",
      )?.id;

      const applicant = payload.applicant;
      const selectedVariableRate = payload?.rates?.find((o: any) => o.type === "Variable");
      const selectedFixedRate = payload?.rates?.find((o: any) => o.type === "Fixed");
      const request = {
        min: payload?.selectedRange?.min,
        max: payload?.selectedRange?.max,
        variableRate: selectedVariableRate?.interestRate?.toFixed(3) || "NULL",
        fixedRate: selectedFixedRate?.interestRate?.toFixed(3) || "NULL",
      };

      const jsonRequest = JSON.stringify(request);
      const surveyResponseRequest = {
        applicantId: applicant?.id,
        questionId: questionId,
        response: jsonRequest,
      } as any;

      const response = await $api.person.addSurveyResponse(surveyResponseRequest);
      return response;
    },
    async getDocumentTypes({ commit }, payload) {
      try {
        const response = await $api.lookups.getDocumentTypes(payload);

        if (response?.results && response?.results?.length > 0) {
          commit("SET_DOCUMENT_TYPES", response.results);
        }
      } catch (error) {
        console.log("Error: getDocumentTypes()", error);
      }
    },
  },
  getters: {
    SOFT_PULL_ENABLED_FLAG: (state): boolean => {
      return state.lenderData?.program?.softPullEnabled ? state.lenderData?.program?.softPullEnabled : false;
    },
    membershipTypeOptions: (state): Array<Option> => {
      return state.membershipTypeOptions;
    },
    updateEmailModalActive: (state): boolean => {
      return state.updateEmailModalActive;
    },
    cuLogoUrl: (state): string => {
      return state.lenderData
        ? new URL(
            `/public/assets/${state.lenderData?.lenderSubdomain || "cusc"}/logo.png`,
            state.configuration.cdnUrl,
          ).toString()
        : "";
    },
    supportPhoneNumber: (state): string => {
      return state.lenderData?.tollFreeNumber ? state.lenderData.tollFreeNumber : "1-844-896-3772";
    },
    states: (state): string => {
      return state.states;
    },
    appData: (state: any) => {
      return state.appData;
    },
    enrollmentTerms: (state: any) => {
      return state.enrollmentTerms;
    },
    primaryApplicantTypeId: (state): number => {
      if (state.lenderData?.applicantTypes?.length > 0) {
        return state.lenderData?.applicantTypes?.find((a: any) => a.name === "Primary Applicant").id || 0;
      } else {
        return 0;
      }
    },
    coApplicantTypeId: (state): number => {
      if (state.lenderData?.applicantTypes?.length > 0) {
        return state.lenderData?.applicantTypes?.find((a: any) => a.name === "Co-Applicant").id || 0;
      } else {
        return 0;
      }
    },
    primaryApplicantPersonId: (state: any) => {
      if (state.appData?.applicants && state.appData?.applicants?.length > 0) {
        return (
          state.appData.applicants.find((p: Applicant) => (p.applicantTypeName || "") === "Primary Applicant").id || 0
        );
      } else {
        return 0;
      }
    },
    coApplicantPersonId: (state: any) => {
      if (state.appData?.applicants && state.appData?.applicants?.length > 0) {
        return state.appData.applicants.find((p: Applicant) => (p.applicantTypeName || "") === "Co-Applicant")?.id || 0;
      } else {
        return 0;
      }
    },
    feedbackFormOptions: (state: any) => {
      return state.feedbackFormOptions;
    },
    itinNationalIdNumberTypeId: (state: any) => {
      return state?.nationalIdNumberTypes?.find((t: LOSType) => t.name === "ITIN")?.id;
    },
    ssnNationalIdNumberTypeId: (state: any) => {
      return state?.nationalIdNumberTypes?.find((t: LOSType) => t.name === "SSN")?.id;
    },
    getCitizenshipTypeOptions: (state: any): any => {
      const coborrowerPathCheck = [
        "coborrowerPersonalInfo",
        "coborrowerInfo",
        "coborrowerInvitedApplicantInfo",
        "refiCosignerPersonalInfo",
        "refiCosignerInfo",
        "refiCosignerInvitedApplicantInfo",
      ].includes(state.appData.currentState?.route);

      const borrowerPathCheck = [
        "borrowerPersonalInfo",
        "borrowerInfo",
        "borrowerInvitedApplicantInfo",
        "refiBorrowerPersonalInfo",
        "refiBorrowerInfo",
        "refiBorrowerInvitedApplicantInfo",
      ].includes(state.appData.currentState?.route);

      if (
        (state.lenderData?.itinAccessTypeId &&
          state.lenderData?.itinAccessTypeName === "Co-Borrower Only" &&
          coborrowerPathCheck) ||
        (state.lenderData?.itinAccessTypeId &&
          state.lenderData?.itinAccessTypeName === "Borrower Only" &&
          borrowerPathCheck) ||
        (state.lenderData?.itinAccessTypeId && state.lenderData?.itinAccessTypeName === "Borrower And Co-Borrower")
      ) {
        return state.citizenshipTypeOptions;
      } else {
        return state.citizenshipTypeOptions.filter((o: any) => o.label !== "Non-Citizen");
      }
    },
    adverseActionNoticeDocumentTypeId: (state: any): any => {
      return state?.documentTypes?.find((t: LOSType) => t.name === "Adverse Action")?.id;
    },
    coApplicantTypeDisplayName: (state: any): string => {
      switch (state.appData?.application?.productTypeId) {
        case 1:
          return "Co-borrower";
        case 2:
          return "Cosigner";
        default:
          return "Co-Applicant";
      }
    },
    primaryApplicantTypeDisplayName: (state: any): string => {
      switch (state.appData?.application?.productTypeId) {
        case 1:
          return "Student Borrower";
        case 2:
          return "Borrower";
        default:
          return "Primary Applicant";
      }
    },
    primaryApplicantTypeName: (state): string => {
      if (state.lenderData?.applicantTypes?.length > 0) {
        return state.lenderData?.applicantTypes?.find((a: any) => a.name === "Primary Applicant")?.name || "";
      } else {
        return "Primary Applicant";
      }
    },
    coApplicantTypeName: (state): string => {
      if (state.lenderData?.applicantTypes?.length > 0) {
        return state.lenderData?.applicantTypes?.find((a: any) => a.name === "Co-Applicant")?.name || "";
      } else {
        return "Co-Applicant";
      }
    },
    PROGRAM_MINIMUM_LOAN_AMOUNT: (state): number => {
      return state.appData?.application?.metadata?.withCoApplicants === true
        ? state?.lenderData?.program?.borrowerMinLoanAmountJoint
        : state?.lenderData?.program?.borrowerMinLoanAmountSolo;
    },
    PROGRAM_MINIMUM_LOAN_AMOUNT_DISPLAY: (state): string => {
      return state.appData?.application?.metadata?.withCoApplicants === true
        ? state?.lenderData?.program?.borrowerMinLoanAmountJointDisplay
        : state?.lenderData?.program?.borrowerMinLoanAmountSoloDisplay;
    },
    productDisplayName: (state: any) => {
      return state?.lenderData?.program?.productName;
    },
  },
  modules: {
    loanConfigurationModule,
    candidateRefiLoansModule,
    showMyRatesModule,
    refiLoanConfigurationModule,
    refiRatesSelfSelectModule,
    applicantPersonalInformationModule,
    applicationDecisionModule,
    documentsModule,
    consentsModule,
  },
});
