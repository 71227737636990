import { $api } from "@/services/api";
import { Option } from "@/models/form";
import { LOSType } from "@/models/opal";
import store from "@/store";

export default function useFormHelpers(): any {
  function months(): any[] {
    return [
      {
        label: "January",
        value: "01",
      },
      {
        label: "February",
        value: "02",
      },
      {
        label: "March",
        value: "03",
      },
      {
        label: "April",
        value: "04",
      },
      {
        label: "May",
        value: "05",
      },
      {
        label: "June",
        value: "06",
      },
      {
        label: "July",
        value: "07",
      },
      {
        label: "August",
        value: "08",
      },
      {
        label: "September",
        value: "09",
      },
      {
        label: "October",
        value: "10",
      },
      {
        label: "November",
        value: "11",
      },
      {
        label: "December",
        value: "12",
      },
    ];
  }

  function years(future: boolean): any[] {
    const current = new Date().getFullYear();
    const years: any[] = [{ label: current.toString(), value: current }];

    if (future) {
      for (let i = 1; i <= 10; i++) {
        years.push({ label: (current + i).toString(), value: current + i });
      }
    } else {
      for (let i = 1; i <= 100; i++) {
        years.push({ label: (current - i).toString(), value: current - i });
      }
    }

    return years;
  }

  function days(year = 0, month = 0): any[] {
    const days = [];
    const dayCount = new Date(year, month, 0).getDate();

    for (let i = 1; i <= dayCount; i++) {
      days.push({ label: i.toString(), value: i });
    }

    return days;
  }

  function phoneTypes(): any[] {
    return [
      {
        label: "Mobile",
        value: "MOBILE",
      },
      {
        label: "Work",
        value: "WORK",
      },
      {
        label: "Home",
        value: "PERM",
      },
      {
        label: "Temporary",
        value: "TEMP",
      },
    ];
  }

  function states(): any[] {
    return [
      {
        label: "Alabama",
        value: "AL",
      },
      {
        label: "Alaska",
        value: "AK",
      },
      {
        label: "American Samoa",
        value: "AS",
      },
      {
        label: "Arizona",
        value: "AZ",
      },
      {
        label: "Arkansas",
        value: "AR",
      },
      {
        label: "California",
        value: "CA",
      },
      {
        label: "Colorado",
        value: "CO",
      },
      {
        label: "Connecticut",
        value: "CT",
      },
      {
        label: "Delaware",
        value: "DE",
      },
      {
        label: "District Of Columbia",
        value: "DC",
      },
      {
        label: "Federated States Of Micronesia",
        value: "FM",
      },
      {
        label: "Florida",
        value: "FL",
      },
      {
        label: "Georgia",
        value: "GA",
      },
      {
        label: "Guam",
        value: "GU",
      },
      {
        label: "Hawaii",
        value: "HI",
      },
      {
        label: "Idaho",
        value: "ID",
      },
      {
        label: "Illinois",
        value: "IL",
      },
      {
        label: "Indiana",
        value: "IN",
      },
      {
        label: "Iowa",
        value: "IA",
      },
      {
        label: "Kansas",
        value: "KS",
      },
      {
        label: "Kentucky",
        value: "KY",
      },
      {
        label: "Louisiana",
        value: "LA",
      },
      {
        label: "Maine",
        value: "ME",
      },
      {
        label: "Marshall Islands",
        value: "MH",
      },
      {
        label: "Maryland",
        value: "MD",
      },
      {
        label: "Massachusetts",
        value: "MA",
      },
      {
        label: "Michigan",
        value: "MI",
      },
      {
        label: "Minnesota",
        value: "MN",
      },
      {
        label: "Mississippi",
        value: "MS",
      },
      {
        label: "Missouri",
        value: "MO",
      },
      {
        label: "Montana",
        value: "MT",
      },
      {
        label: "Nebraska",
        value: "NE",
      },
      {
        label: "Nevada",
        value: "NV",
      },
      {
        label: "New Hampshire",
        value: "NH",
      },
      {
        label: "New Jersey",
        value: "NJ",
      },
      {
        label: "New Mexico",
        value: "NM",
      },
      {
        label: "New York",
        value: "NY",
      },
      {
        label: "North Carolina",
        value: "NC",
      },
      {
        label: "North Dakota",
        value: "ND",
      },
      {
        label: "Northern Mariana Islands",
        value: "MP",
      },
      {
        label: "Ohio",
        value: "OH",
      },
      {
        label: "Oklahoma",
        value: "OK",
      },
      {
        label: "Oregon",
        value: "OR",
      },
      {
        label: "Palau",
        value: "PW",
      },
      {
        label: "Pennsylvania",
        value: "PA",
      },
      {
        label: "Puerto Rico",
        value: "PR",
      },
      {
        label: "Rhode Island",
        value: "RI",
      },
      {
        label: "South Carolina",
        value: "SC",
      },
      {
        label: "South Dakota",
        value: "SD",
      },
      {
        label: "Tennessee",
        value: "TN",
      },
      {
        label: "Texas",
        value: "TX",
      },
      {
        label: "Utah",
        value: "UT",
      },
      {
        label: "Vermont",
        value: "VT",
      },
      {
        label: "Virgin Islands",
        value: "VI",
      },
      {
        label: "Virginia",
        value: "VA",
      },
      {
        label: "Washington",
        value: "WA",
      },
      {
        label: "West Virginia",
        value: "WV",
      },
      {
        label: "Wisconsin",
        value: "WI",
      },
      {
        label: "Wyoming",
        value: "WY",
      },
    ];
  }

  function rateTypes(): any[] {
    return [
      {
        label: "Fixed",
        value: "",
      },
      {
        label: "Variable",
        value: "",
      },
    ];
  }

  function conciergeOptions(): any[] {
    return [
      {
        label: "No, thanks",
        value: false,
      },
      {
        label: "Yes",
        value: true,
      },
    ];
  }

  function choosePathOptions(): any[] {
    return [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ];
  }

  function schoolPlanOptions(): any[] {
    return [
      {
        label: "Yes",
        value: false,
      },
      {
        label: "No",
        value: true,
      },
    ];
  }

  function enrollmentOptions(): any[] {
    return [
      {
        label: "Half-Time",
        value: false,
      },
      {
        label: "Full-Time",
        value: true,
      },
    ];
  }

  function addCoborrowerOptions(): any[] {
    return [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "I would like to continue by myself",
        value: false,
      },
    ];
  }

  function repaymentTypes(): any[] {
    return [
      {
        label: "Flat",
        value: "",
      },
      {
        label: "Deferred",
        value: "",
      },
      {
        label: "Interst",
        value: "",
      },
      {
        label: "Regular",
        value: "",
      },
    ];
  }

  function incomeRequirementOptions(): any[] {
    return [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ];
  }

  function isPresentOptions(): any[] {
    return [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ];
  }

  function housingOptions(applicantType: string): Option[] {
    if (applicantType === "Primary Applicant") {
      return [
        {
          label: "Live with Family or on Campus",
          value: "P",
        },
        {
          label: "Rent",
          value: "R",
        },
        {
          label: "Own - With Mortgage",
          value: "O",
        },
        {
          label: "Own - Without Mortgage",
          value: "Q",
        },
      ] as Option[];
    } else {
      return [
        {
          label: "Own - With Mortgage",
          value: "O",
        },
        {
          label: "Own - Without Mortgage",
          value: "Q",
        },
        {
          label: "Rent",
          value: "R",
        },
        {
          label: "Other",
          value: "P",
        },
      ] as Option[];
    }
  }

  function mobileConsentOptions(): any[] {
    return [
      {
        label: "Opt-in",
        value: true,
      },
      {
        label: "Opt-out",
        value: false,
      },
    ] as Option[];
  }

  function membershipOptions(): any[] {
    return [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ] as Option[];
  }

  function showMyRatesOptions(): any[] {
    return [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ];
  }

  function maritalStatusOptions(): any[] {
    return [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ];
  }

  async function spouseInformationFormLookups() {
    let addressUnitLookup = [] as any[];
    let addressUnitOptions = [] as Option[];
    let statesOptions = [] as Option[];

    const lookups = await $api.lookups.getPersonalInfoLookups();
    if (lookups.addressUnits) {
      addressUnitLookup = lookups.addressUnits;
      const addressUnits = lookups.addressUnits.map((addressUnit: any) => {
        return {
          label: addressUnit.name,
          value: addressUnit.id,
        };
      });
      addressUnitOptions = addressUnits;
    }

    if (lookups.states) {
      const states = lookups.states.map((state: any) => {
        return {
          label: state.name,
          value: state.id,
        };
      });
      statesOptions = states;
    }

    return {
      addressUnitLookup,
      addressUnitOptions,
      statesOptions,
    };
  }

  async function personalInformationFormLookups() {
    let addressUnitTypeOptions = [] as Option[];
    let statesOptions = [] as Option[];
    let citizenshipOptions = [] as Option[];
    const addressUnitTypePrefixes = [] as string[];

    const keysArray = Object.keys(store.state.personalInfoFormLookups) || [];
    const count = keysArray.length;

    if (count <= 0) {
      const lookups = await $api.lookups.getPersonalInfoLookups();
      store.dispatch("setPersonalInfoFormLookups", lookups);

      if (lookups.addressUnits) {
        lookups.addressUnits.forEach((au: any) => {
          addressUnitTypePrefixes.push(au?.abbreviation);
          addressUnitTypePrefixes.push(au?.name);
        });

        const addressUnits = lookups.addressUnits.map((addressUnit: any) => {
          return {
            label: addressUnit.name,
            value: addressUnit.id,
          };
        });

        (addressUnitTypeOptions = addressUnits) as Option[];
        store.dispatch("setAddressUnitTypePrefixes", addressUnitTypePrefixes);
        store.dispatch("setAddressUnitTypes", lookups.addressUnits);
        store.dispatch("setAddressUnitTypeOptions", addressUnitTypeOptions);
      }

      if (lookups.states) {
        const states = lookups.states.map((state: any) => {
          return {
            label: state.name,
            value: state.id,
          };
        });
        statesOptions = states as Option[];

        store.dispatch("setStates", lookups.states);
        store.dispatch("setStateOptions", statesOptions);
      }

      if (lookups.citizenshipStatus) {
        const citizenshipStatus = lookups.citizenshipStatus.map((option: any) => {
          return {
            label: option.name,
            value: parseInt(option.id),
          };
        });

        citizenshipOptions = citizenshipStatus as Option[];
        store.dispatch("setCitizenshipTypes", lookups.citizenshipStatus);
        store.dispatch("setCitizenshipTypeOptions", citizenshipOptions);
      }
    }
  }

  function setNationalIdNumberTypeId(nationalIdNumberTypeId: any) {
    const itinNationalIdNumberTypeId = store.state?.personalInfoFormLookups?.nationalIdNumberTypes?.find(
      (t: LOSType) => t.name === "ITIN",
    )?.id;
    const ssnNationalIdNumberTypeId = store.state?.personalInfoFormLookups?.nationalIdNumberTypes?.find(
      (t: LOSType) => t.name === "SSN",
    )?.id;

    switch (nationalIdNumberTypeId) {
      case itinNationalIdNumberTypeId:
        return itinNationalIdNumberTypeId;
      default:
        return ssnNationalIdNumberTypeId;
    }
  }

  function setNationalIdNumberTypeIdFieldSubtype(nationalIdNumberTypeId: number) {
    const itinNationalIdNumberTypeId = store.state?.personalInfoFormLookups?.nationalIdNumberTypes?.find(
      (t: LOSType) => t.name === "ITIN",
    )?.id;
    const ssnNationalIdNumberTypeId = store.state?.personalInfoFormLookups?.nationalIdNumberTypes?.find(
      (t: LOSType) => t.name === "SSN",
    )?.id;

    switch (nationalIdNumberTypeId) {
      case itinNationalIdNumberTypeId:
        return "itin";
      default:
        return "ssn";
    }
  }

  async function schoolInformationFormLookups(programTypeId?: number) {
    const lookups = await $api.lookups.getSchoolInfoLookups(programTypeId);

    if (lookups.coursesOfStudy) {
      const options = lookups.coursesOfStudy.map((o: any) => {
        return {
          label: o.description,
          value: (o.majorName || "").trim(),
        };
      });
      return options as Option[];
    }
  }

  async function coursesOfStudy(programTypeId?: number) {
    const lookups = await $api.lookups.getSchoolInfoLookups(programTypeId);
    if (lookups.coursesOfStudy) {
      const options = lookups.coursesOfStudy.map((o: any) => {
        return {
          label: o.description,
          value: (o.majorName || "").trim(),
        };
      });
      return options as Option[];
    }

    if (lookups.schoolLevels) {
      const options = lookups.schoolLevels.map((o: any) => {
        return {
          label: o.name,
          value: o.id || "",
        };
      });
      return options as Option[];
    }
  }

  async function schoolLevels(programTypeId?: number) {
    const lookups = await $api.lookups.getSchoolInfoLookups(programTypeId);
    if (lookups.schoolLevels) {
      const options = lookups.schoolLevels.map((o: any) => {
        return {
          label: o.name,
          value: o.id || "",
        };
      });
      return options as Option[];
    }
  }

  async function contactMethods() {
    const lookups = await $api.lookups.getApprovalLookUps();
    if (lookups.contactTypes) {
      const options = lookups.contactTypes.map((option: any) => {
        return {
          label: option.name,
          value: option.id,
        };
      });
      return options as Option[];
    }
  }

  async function phoneTypeOptions() {
    const lookups = await $api.lookups.getRegistrationLookups();
    if (lookups.phoneTypes.length > 0) {
      const options = lookups.phoneTypes.map((type: any) => {
        return {
          label: type.name,
          value: type.id,
        };
      });
      return options as Option[];
    }
  }

  async function statesOptions() {
    const lookups = await $api.lookups.getPersonalInfoLookups();
    if (lookups.states) {
      const options = lookups.states.map((state: any) => {
        return {
          label: state.name,
          value: state.id,
        };
      });
      return options as Option[];
    }
  }

  async function addressUnitAbbreviations() {
    const lookups = await $api.lookups.getPersonalInfoLookups();
    if (lookups.addressUnits) {
      const options = lookups.addressUnits.map((addressUnit: any) => {
        return {
          label: addressUnit.id,
          abbreviation: addressUnit.abbreviation,
        };
      });
      return options as any[];
    }
  }

  async function addressUnitOptions() {
    const lookups = await $api.lookups.getPersonalInfoLookups();
    if (lookups.addressUnits) {
      const options = lookups.addressUnits.map((addressUnit: any) => {
        return {
          label: addressUnit.name,
          value: addressUnit.id,
        };
      });
      return options as Option[];
    }
  }

  async function citizenshipOptions() {
    const lookups = await $api.lookups.getPersonalInfoLookups();
    if (lookups.citizenshipStatus) {
      const options = lookups.citizenshipStatus.map((option: any) => {
        return {
          label: option.name,
          value: parseInt(option.id),
        };
      });

      return options as Option[];
    }
  }

  function ageOfMajority(state: string) {
    let ageOfMajority = 18;
    switch (state) {
      case "1":
      case "AL":
        return (ageOfMajority = 19);
      case "46":
      case "PR":
        return (ageOfMajority = 21);
      default:
        return (ageOfMajority = 18);
    }
  }

  async function calculateAge(dateStr: string) {
    const dob = new Date(dateStr);
    if (dateStr !== null) {
      const response = await $api.person.calculateApplicantAge(dob);

      if (response?.errors && response?.status === 400) {
        return false;
      }

      return response;
    } else {
      return 0;
    }
  }

  function convertDateOfBirthToAge(dobInput: string) {
    const birthDate = new Date(dobInput);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    // Adjust age if the birthday hasn't happened yet this year
    // monthDiff < 0 indicates future months that have not happened yet
    // monthDiff === 0 && dayDiff <= 0 indicates future dates in the same month that havent happened yet
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff <= 0)) {
      age--;
    }

    // Check for leap year
    function isLeapYear(year: number) {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }

    // Adjust for leap year if the birthday is on February 29th
    if (birthDate.getMonth() === 1 && birthDate.getDate() === 29 && !isLeapYear(today.getFullYear())) {
      age++; // Add one year if the current year is not a leap year
    }

    return age;
  }

  function getDaysOfMonth(dobMonth: any, dobYear: any) {
    if (dobYear && dobMonth) {
      return getDays(dobYear, dobMonth);
    } else if (dobMonth && !dobYear) {
      return getDays(0, dobMonth);
    } else {
      return days();
    }
  }

  function getDays(year: number, month: number) {
    // bug 5160
    // https://natclark.com/tutorials/javascript-days-in-month/

    const days = [];
    const dayCount = new Date(year, month, 0).getDate();

    for (let i = 1; i <= dayCount; i++) {
      days.push({ label: i.toString(), value: i });
    }

    return days;
  }

  function compareTwoDates(date1: Date, date2: Date) {
    const date1Str = `${date1.getMonth()}/${date1.getDay()}/${date1.getFullYear()}`;
    const date2Str = `${date2.getMonth()}/${date2.getDay()}/${date2.getFullYear()}`;

    return date1Str === date2Str;
  }

  function sortLosTypesByDescription(array: Array<any>) {
    if (array && array.length > 0) {
      array.sort((a: any, b: any) => {
        if (b.description < a.description) return 1;
        if (b.description > a.description) return -1;
        return 0;
      });
      return array;
    } else {
      return [];
    }
  }

  function sortLosTypesByName(array: Array<any>) {
    // only sort if array has items
    if (array && array.length > 0) {
      array.sort((a: any, b: any) => {
        if (b.name < a.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      });
      return array;
    } else {
      return [];
    }
  }

  function sortSchoolsBySchoolName(array: Array<any>) {
    if (array && array.length > 0) {
      array.sort((a: any, b: any) => {
        if (b.schoolName < a.schoolName) return 1;
        if (b.schoolName > a.schoolName) return -1;
        return 0;
      });
      return array;
    } else {
      return [];
    }
  }

  return {
    months,
    years,
    days,
    phoneTypes,
    states,
    rateTypes,
    conciergeOptions,
    choosePathOptions,
    schoolPlanOptions,
    enrollmentOptions,
    addCoborrowerOptions,
    repaymentTypes,
    incomeRequirementOptions,
    isPresentOptions,
    housingOptions,
    mobileConsentOptions,
    membershipOptions,
    showMyRatesOptions,
    maritalStatusOptions,
    spouseInformationFormLookups,
    personalInformationFormLookups,
    schoolInformationFormLookups,
    getDays,
    getDaysOfMonth,
    coursesOfStudy,
    schoolLevels,
    phoneTypeOptions,
    contactMethods,
    statesOptions,
    addressUnitAbbreviations,
    addressUnitOptions,
    citizenshipOptions,
    ageOfMajority,
    calculateAge,
    compareTwoDates,
    sortSchoolsBySchoolName,
    sortLosTypesByDescription,
    sortLosTypesByName,
    setNationalIdNumberTypeId,
    setNationalIdNumberTypeIdFieldSubtype,
    convertDateOfBirthToAge,
  };
}
