import { createApp, DefineComponent } from "vue";
// Only needed when authentication is added
// import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Maska from "maska";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircle as farCircle, faCircleCheck as farCircleCheck } from "@fortawesome/free-regular-svg-icons";
import {
  faEye,
  faEyeSlash,
  faSave,
  faHeadset,
  faPhone,
  faComment,
  faCircle as fasCircle,
  faEnvelope as fasEnvelope,
  faCircleInfo,
  faXmark,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCircleExclamation,
  faFloppyDisk,
  faTrash,
  faPenToSquare,
  faPencil,
  faX,
  faTriangleExclamation,
  faDownload,
  faAngleUp,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { getEnvironment } from "./config/environment";
import VueGtag from "vue-gtag";
library.add(
  faEye,
  faEyeSlash,
  faSave,
  faHeadset,
  faPhone,
  faComment,
  farCircle,
  fasCircle,
  fasEnvelope,
  faCircleInfo,
  faXmark,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faCircleExclamation,
  faFloppyDisk,
  faTrash,
  faPenToSquare,
  faPencil,
  farCircleCheck,
  faX,
  faTriangleExclamation,
  faDownload,
  faPencil,
  faAngleUp,
  faAngleDown,
);
// TODO: Refactor to only add token to certain endpoints that require it
// axios.interceptors.request.use(
//   (config: any) => {
//     return {
//       ...config,
//       headers: {
//         ...config.headers,
//         Authorization: `Bearer ${store.getters["oidcStore/oidcAccessToken"]}`
//       }
//     };
//   },
//   (error: any) => Promise.reject(error)
// );

// Required for google analytics
const environment = getEnvironment();

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(Maska)
  .use(VueGtag, {
    config: { id: environment.googleAnalyticsId },
    router,
    enabled: true,
    params: {
      send_page_view: false,
    },
  });

app
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("font-awesome-layers", FontAwesomeLayers)
  .component("font-awesome-layer-text", FontAwesomeLayersText);

app.provide("gtag", app.config.globalProperties.$gtag);
app.mount("#app");
