import { Module } from "vuex";
import { $api } from "@/services/api";
import store from "@/store";
import { PaymentCalculationViewModel, SoftPullResultViewModel } from "@/models/viewModels";
import useFormatter from "@/use/formatter";
import useArrayHelpers from "@/use/arrayHelpers";

const { formatNumber, formatMoney } = useFormatter();
const { groupBy } = useArrayHelpers();

const showMyRatesModule: Module<any, any> = {
  namespaced: true,
  state: {
    gettingEstimatedPayments: false as boolean,
    calculatingEstimatedPayments: false as boolean,
    estimatedLoanAmount: 0 as number,
    estimatedLoanAmountInput: 0 as number,
    calculatedPaymentOptions: [] as PaymentCalculationViewModel[],
    sampleCalculatedPaymentOptions: [] as PaymentCalculationViewModel[],
    mockRateTermsAvailable: {
      5: [
        { interestRate: 0.0285, rateTypeName: "Fixed" },
        { interestRate: 0.0221, rateTypeName: "Variable" },
      ],
      10: [
        { interestRate: 0.0785, rateTypeName: "Fixed" },
        { interestRate: 0.121, rateTypeName: "Variable" },
      ],
      15: [
        { interestRate: 0.0785, rateTypeName: "Fixed" },
        { interestRate: 0.121, rateTypeName: "Variable" },
      ],
    } as any,
    mockEstimatedLoanAmountCalculationRates: [
      {
        ratePlanId: 1,
        year: 5,
        month: 5 * 12,
        fixedRate: 2.96,
        variableRate: 3.26,
      },
      {
        ratePlanId: 2,
        year: 10,
        month: 10 * 12,
        fixedRate: 3.26,
        variableRate: 3.26,
      },
      {
        ratePlanId: 3,
        year: 15,
        month: 15 * 12,
        fixedRate: 5.26,
        variableRate: 3.26,
      },
    ],
    mockEstimatedMonthlyPaymentOptions: [
      {
        ratePlanId: 1,
        year: 5,
        month: 5 * 12,
        fixedMonthlyPayment: 346.87,
        variableMonthlyPayment: 349.45,
        fixedRate: 2.96,
        variableRate: 3.26,
      },
      {
        ratePlanId: 2,
        year: 10,
        month: 10 * 12,
        fixedMonthlyPayment: 188.91,
        variableMonthlyPayment: 188.91,
        fixedRate: 3.26,
        variableRate: 3.26,
      },
      {
        ratePlanId: 3,
        year: 15,
        month: 15 * 12,
        fixedMonthlyPayment: 155.43,
        variableMonthlyPayment: 135.87,
        fixedRate: 5.26,
        variableRate: 3.26,
      },
    ],
    mockAvailablePricingTiers: [
      {
        id: 109,
        programId: 12,
        termId: 0,
        ficoMin: 800,
        ficoMax: 824,
        rateId: 0,
        dtiMax: 999.9999,
        effectiveDate: "2022-10-27T00:00:00",
        expirationDate: null,
        applicantType: {
          id: 1,
          name: "Primary Applicant",
        },
        rate: {
          id: 15,
          rateTypeId: 2,
          margin: 1.7,
          floor: 0.0,
          ceiling: 18.0,
          indexType: {
            id: 2,
            name: "Prime",
            rate: 8.5,
          },
          rateType: {
            id: 2,
            name: "Variable",
          },
        },
        term: {
          id: 1,
          years: 5.0,
          months: 60,
        },
      },
      {
        id: 110,
        programId: 12,
        termId: 0,
        ficoMin: 800,
        ficoMax: 824,
        rateId: 0,
        dtiMax: 999.9999,
        effectiveDate: "2022-10-27T00:00:00",
        expirationDate: null,
        applicantType: {
          id: 1,
          name: "Primary Applicant",
        },
        rate: {
          id: 18,
          rateTypeId: 2,
          margin: 4.65,
          floor: 0.0,
          ceiling: 18.0,
          indexType: {
            id: 1,
            name: "None",
            rate: 0.0,
          },
          rateType: {
            id: 1,
            name: "Fixed",
          },
        },
        term: {
          id: 1,
          years: 5.0,
          months: 60,
        },
      },
      {
        id: 103,
        programId: 12,
        termId: 0,
        ficoMin: 800,
        ficoMax: 824,
        rateId: 0,
        dtiMax: 999.9999,
        effectiveDate: "2022-10-27T00:00:00",
        expirationDate: null,
        applicantType: {
          id: 1,
          name: "Primary Applicant",
        },
        rate: {
          id: 15,
          rateTypeId: 2,
          margin: 1.7,
          floor: 0.0,
          ceiling: 18.0,
          indexType: {
            id: 2,
            name: "Prime",
            rate: 8.5,
          },
          rateType: {
            id: 2,
            name: "Variable",
          },
        },
        term: {
          id: 4,
          years: 15.0,
          months: 180,
        },
      },
      {
        id: 106,
        programId: 12,
        termId: 0,
        ficoMin: 800,
        ficoMax: 824,
        rateId: 0,
        dtiMax: 999.9999,
        effectiveDate: "2022-10-27T00:00:00",
        expirationDate: null,
        applicantType: {
          id: 1,
          name: "Primary Applicant",
        },
        rate: {
          id: 18,
          rateTypeId: 2,
          margin: 4.65,
          floor: 0.0,
          ceiling: 18.0,
          indexType: {
            id: 1,
            name: "None",
            rate: 0.0,
          },
          rateType: {
            id: 1,
            name: "Fixed",
          },
        },
        term: {
          id: 4,
          years: 15.0,
          months: 180,
        },
      },
      {
        id: 107,
        programId: 12,
        termId: 0,
        ficoMin: 800,
        ficoMax: 824,
        rateId: 0,
        dtiMax: 999.9999,
        effectiveDate: "2022-10-27T00:00:00",
        expirationDate: null,
        applicantType: {
          id: 1,
          name: "Primary Applicant",
        },
        rate: {
          id: 25,
          rateTypeId: 1,
          margin: 5.85,
          floor: 5.85,
          ceiling: 5.85,
          indexType: {
            id: 1,
            name: "None",
            rate: 0.0,
          },
          rateType: {
            id: 1,
            name: "Fixed",
          },
        },
        term: {
          id: 3,
          years: 10.0,
          months: 120,
        },
      },
      {
        id: 108,
        programId: 12,
        termId: 0,
        ficoMin: 800,
        ficoMax: 824,
        rateId: 0,
        dtiMax: 999.9999,
        effectiveDate: "2022-10-27T00:00:00",
        expirationDate: null,
        applicantType: {
          id: 1,
          name: "Primary Applicant",
        },
        rate: {
          id: 26,
          rateTypeId: 1,
          margin: 6.6,
          floor: 6.6,
          ceiling: 6.6,
          indexType: {
            id: 1,
            name: "None",
            rate: 0.0,
          },
          rateType: {
            id: 1,
            name: "Fixed",
          },
        },
        term: {
          id: 3,
          years: 10.0,
          months: 120,
        },
      },
    ],
    currentApplicantTypeId: 0 as number,
    currentApplicantTypeName: "" as string,
    primaryApplicantSelectedCreditScoreRange: {},
    coApplicantSelectedCreditScoreRange: {},
    selectedCreditScoreRange: {},
    availablePricingTiers: [],
    softPullRatePlans: {} as any,
    primaryApplicantSoftPullRatePlans: [] as any[],
    coApplicantSoftPullRatePlans: [] as any[],
    currentSubpageId: "" as string,
    softPullResult: {} as SoftPullResultViewModel,
  },
  mutations: {
    SET_AVAILABLE_PRICING_TIERS(state: any, pricingTiers: any) {
      state.availablePricingTiers = pricingTiers;
    },
    SET_SOFT_PULL_RATE_PLANS(state: any, ratePlans: any) {
      state.softPullRatePlans = ratePlans;
    },
    SET_PRIMARY_APPLICANT_SOFT_PULL_RATE_PLANS(state: any, ratePlans: any) {
      state.primaryApplicantSoftPullRatePlans = ratePlans;
    },
    SET_CO_APPLICANT_SOFT_PULL_RATE_PLANS(state: any, ratePlans: any) {
      state.coApplicantSoftPullRatePlans = ratePlans;
    },
    SET_CURRENT_APPLICANT_TYPE_ID(state: any, currentApplicantTypeId: number) {
      state.currentApplicantTypeId = currentApplicantTypeId;
    },
    SET_CURRENT_APPLICANT_TYPE_NAME(state: any, currentApplicantTypeName: string) {
      state.currentApplicantTypeName = currentApplicantTypeName;
    },
    SET_GETTING_ESTIMATED_PAYMENTS(state: any, flag: boolean) {
      state.gettingEstimatedPayments = flag;
    },
    SET_CALCULATING_ESTIMATED_PAYMENTS(state: any, flag: boolean) {
      state.calculatingEstimatedPayments = flag;
    },
    SET_ESTIMATED_LOAN_AMOUNT(state: any, amount: number) {
      state.estimatedLoanAmount = amount;
    },
    SET_ESTIMATED_LOAN_AMOUNT_INPUT(state: any, amount: number) {
      const number = typeof amount === "string" ? parseInt(amount) : amount;
      state.estimatedLoanAmountInput = number;
    },
    SET_CALCULATED_PAYMENT_OPTIONS(state: any, calculatedPaymentOptions: PaymentCalculationViewModel[]) {
      state.calculatedPaymentOptions = calculatedPaymentOptions;
    },
    SET_SAMPLE_CALCULATED_PAYMENT_OPTIONS(state: any, calculatedPaymentOptions: PaymentCalculationViewModel[]) {
      state.sampleCalculatedPaymentOptions = calculatedPaymentOptions;
    },
    SET_CURRENT_SUBPAGE_ID(state: any, subpageId: string) {
      state.currentSubpageId = subpageId;
    },
    SET_SOFT_PULL_RESULT(state: any, softPullResult: SoftPullResultViewModel) {
      state.softPullResult = softPullResult;
    },
  },
  actions: {
    setCalculatingEstimatedPayments({ commit }, payload) {
      commit("SET_CALCULATING_ESTIMATED_PAYMENTS", payload);
    },
    setGettingEstimatedPayments({ commit }, payload) {
      commit("SET_GETTING_ESTIMATED_PAYMENTS", payload);
    },
    setCalculatedPaymentOptions({ commit }, payload) {
      commit("SET_CALCULATED_PAYMENT_OPTIONS", payload);
    },
    setSampleCalculatedPaymentOptions({ commit }, payload) {
      commit("SET_SAMPLE_CALCULATED_PAYMENT_OPTIONS", payload);
    },
    setEstimatedLoanAmount({ commit }, payload) {
      commit("SET_ESTIMATED_LOAN_AMOUNT", payload);
    },
    setCurrentApplicantTypeId({ commit }, payload) {
      commit("SET_CURRENT_APPLICANT_TYPE_ID", payload);
    },
    setCurrentSubpageId({ commit }, payload) {
      commit("SET_CURRENT_SUBPAGE_ID", payload);
    },
    setSoftPullResult({ commit }, payload) {
      commit("SET_SOFT_PULL_RESULT", payload);
    },
    async calculateSamplePayments({ state, commit, dispatch }, payload: any) {
      dispatch("setCalculatingEstimatedPayments", true);

      const request = {
        applicantId: payload?.applicantId,
        estimatedLoanAmount: payload?.asdAmtFinanced,
      };

      const payments = await $api.refi.calculatePayments(payload?.applicantId, request);

      if (payments?.length > 0 && !payments?.error) {
        const sortedPaymentsAsc = payments?.sort(
          (a: PaymentCalculationViewModel, b: PaymentCalculationViewModel) =>
            parseInt(a?.termYears) - parseInt(b?.termYears),
        );
        commit("SET_SAMPLE_CALCULATED_PAYMENT_OPTIONS", sortedPaymentsAsc);
      } else {
        commit("SET_SAMPLE_CALCULATED_PAYMENT_OPTIONS", []);
      }

      dispatch("setCalculatingEstimatedPayments", false);
    },
    async calculatePayments({ state, commit, dispatch }, applicantId: number) {
      dispatch("setCalculatingEstimatedPayments", true);

      const request = {
        applicantId: applicantId,
        estimatedLoanAmount: state.estimatedLoanAmount,
      };

      const payments = await $api.refi.calculatePayments(applicantId, request);

      if (payments?.length > 0 && !payments?.error) {
        const sortedPaymentsAsc = payments?.sort(
          (a: PaymentCalculationViewModel, b: PaymentCalculationViewModel) =>
            parseInt(a?.termYears) - parseInt(b?.termYears),
        );
        commit("SET_CALCULATED_PAYMENT_OPTIONS", sortedPaymentsAsc);
      } else {
        commit("SET_CALCULATED_PAYMENT_OPTIONS", []);
      }

      dispatch("setCalculatingEstimatedPayments", false);
    },
    async getSoftPullRatePlans({ state, commit, dispatch }, applicantId: number) {
      const ratePlansResponse = await $api.refi.getSoftPullRatePlans(applicantId);

      if (ratePlansResponse?.ratePlans && ratePlansResponse?.ratePlans?.length > 0) {
        if (state.currentApplicantTypeId === 1) {
          commit("SET_PRIMARY_APPLICANT_SOFT_PULL_RATE_PLANS", ratePlansResponse?.ratePlans);
        }
        if (state.currentApplicantTypeId === 2) {
          commit("SET_CO_APPLICANT_SOFT_PULL_RATE_PLANS", ratePlansResponse?.ratePlans);
        }
      }
    },
  },
  getters: {
    loanMinNotMetError: (state: any): boolean => {
      const minimumLoanAmount = store.getters["PROGRAM_MINIMUM_LOAN_AMOUNT"];
      return state.estimatedLoanAmount >= 0 && state.estimatedLoanAmount < minimumLoanAmount;
    },
    ShowSamplePaymentCalculations: (state: any): any => {
      return (
        store.state.appData.currentState?.route === "refiShowMyRates" &&
        ((state.currentSubpageId === "VeryWellCreditQualified" && state.currentApplicantTypeId === 1) ||
          (state.currentSubpageId === "CreditQualified" && state.currentApplicantTypeId === 1) ||
          state.currentSubpageId === "SoftDeclineBorrowerLedSolo" ||
          state.currentSubpageId === "SoftDeclineBorrowerLedJoint") &&
        store.state.candidateRefiLoansModule?.candidateRefiLoans?.length === 0
      );
    },
    AvailableRatePlanOptions: (state: any): any => {
      const softPullRatePlans =
        state.currentApplicantTypeName === "Co-Applicant"
          ? state.coApplicantSoftPullRatePlans
          : state.primaryApplicantSoftPullRatePlans;
      if (softPullRatePlans && softPullRatePlans?.length > 0) {
        return softPullRatePlans.map((p: any) => {
          return {
            interestRate: p.rate,
            termInMonths: p.year * 12,
            termInYears: p.year,
            rateTypeName: p.rateTypeName ? p.rateTypeName : "",
            year: p.year,
            rate: p.rate,
          };
        });
      } else {
        return [];
      }
    },
    SortedAvailableRateOptions: (state: any): any => {
      const softPullRatePlans =
        state.currentApplicantTypeName === "Co-Applicant"
          ? state.coApplicantSoftPullRatePlans
          : state.primaryApplicantSoftPullRatePlans;
      if (softPullRatePlans && softPullRatePlans?.length > 0) {
        const availableRates = softPullRatePlans.map((p: any) => {
          return {
            interestRate: `${formatNumber(p.rate * 100)}%`,
            termInMonths: p.repaymentTerm,
            termInYears: p.repaymentTerm / 12,
            rateTypeName: p.rateTypeName,
          };
        });
        return groupBy(availableRates, "termInYears");
      } else {
        return [];
      }
    },
    SoftPullRatePlanOptions: (state: any): any => {
      const softPullRatePlans =
        state.currentApplicantTypeName === "Co-Applicant"
          ? state.coApplicantSoftPullRatePlans
          : state.primaryApplicantSoftPullRatePlans;
      return softPullRatePlans;
    },
    SoftPullAvailableTerms: (state: any): any => {
      const softPullRatePlans =
        state.currentApplicantTypeName === "Co-Applicant"
          ? state.coApplicantSoftPullRatePlans
          : state.primaryApplicantSoftPullRatePlans;

      return softPullRatePlans.map((rp: any) => rp.year);
    },
  },
};

export default showMyRatesModule;
